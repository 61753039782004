<template>
  <div class="wedding-socials">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="main-img">
          <div class="cover-img">
            <img src="../assets/weddings-cover.png" class="" alt="" />
          </div>
          <img src="../assets/TT-Horizontal-Light.svg" class="logo" alt="" />
          <div class="bottom-part">
            <div class="left-text">
              <div class="header-text">
                <h2>Crowdfund your</h2>
                <h2>Wedding with a Party!</h2>
              </div>
              <p>
                Thousands of "wedding socials" have been thrown in a little
                place called Manitoba, paying for thousands of weddings.
              </p>
            </div>
            <div class="right-part">
              <diyobo-button
                type="tertiary"
                txt="Get Started!"
                @click="openForm"
              />
              <span class="offer"> $399</span>
              <span>Now Just $29</span>
            </div>
          </div>
        </div>
        <hr class="divider" />
        <div class="features">
          <h3>What do you get?</h3>
          <div class="inner-features">
            <iframe
              class="main-video"
              height="315"
              src="https://www.youtube.com/embed/aX_etYYoGsQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div class="list-features">
              <div class="list-inner">
                <div class="list-img">
                  <img src="../assets/Tote.svg" alt="" />
                </div>
                <div class="list-right">
                  <h4 class="list-heading">A Wedding Social Guide</h4>
                  <p class="list-text">
                    Our guide will walk you through the critical process to plan
                    your very own wedding social.
                  </p>
                </div>
              </div>
              <div class="list-inner">
                <div class="list-img">
                  <img src="../assets/Tote.svg" alt="" />
                </div>
                <div class="list-right">
                  <h4 class="list-heading">
                    Planning and Production Checklists
                  </h4>
                  <p class="list-text">
                    Actionable and interactive checklists that you can share
                    with everyone involved with your social.
                  </p>
                </div>
              </div>
              <div class="list-inner">
                <div class="list-img">
                  <img src="../assets/Tote.svg" alt="" />
                </div>
                <div class="list-right">
                  <h4 class="list-heading">Resources</h4>
                  <p class="list-text">
                    Sponsorship letters for prizes, templates for banner and
                    more!
                  </p>
                </div>
              </div>
              <div class="list-inner">
                <div class="list-img">
                  <img src="../assets/Tote.svg" alt="" />
                </div>
                <div class="list-right">
                  <h4 class="list-heading">Discounted Ticketing Fees</h4>
                  <p class="list-text">
                    Anyone who signs up for our Wedding Crowdfund kit gets
                    discounted ticketing fees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="divider" />

        <div class="testimonials">
          <h3>Testimonials</h3>
          <div class="testimonials-inner">
            <div class="testimonial">
              <div class="img">
                <img src="../assets/Testimonial-01.png" alt="" />
              </div>
              <div class="quote">
                Our wedding social paid for our wedding and then some!
              </div>
              <span>- Erin M</span>
            </div>
            <div class="testimonial">
              <div class="img">
                <img src="../assets/Testimonial-02.png" alt="" />
              </div>
              <div class="quote">
                We were able to have our dream wedding because of our social!
              </div>
              <span>- Tylyla W</span>
            </div>
            <div class="testimonial">
              <div class="img">
                <img src="../assets/Testimonial-03.png" alt="" />
              </div>
              <div class="quote">
                No one had heard of a social here in Vegas. We're planning one
                and everyone's excited.
              </div>
              <span>- Jasper D</span>
            </div>
          </div>
        </div>

        <div class="get-started">
          <div class="right-part">
            <diyobo-button
              type="tertiary"
              txt="Get Started!"
              @click="openForm"
            />
            <span class="offer"> $399</span>
            <span>Now Just $29</span>
          </div>
        </div>
      </div>

      <!-- <img src="../assets/wedding-social-page.jpg" alt="" /> -->
    </div>
  </div>
</template>

<script>
// import PublicLayout from "../../NoHeader.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";

export default {
  components: {
    DiyoboButton
  },
  methods: {
    openForm() {
      let link = this.$router.resolve("wedding-form");
      window.open(link.href, "_blank");
      console.log(link);
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  display: none !important;
}
h1,
h2,
h3,
h4 {
  color: var(--text);
}
// .main-video {
//   position: absolute;
//   top: 45%;
//   width: 430px;
//   right: 54%;
//   height: 17%;
//   border-radius: 8px;
// }
div.main-img {
  // background-image: url(../assets/weddings-cover.png);
  position: relative;
  .cover-img {
    width: 100%;
    img {
      width: 100%;
    }
  }
  img.logo {
    width: 300px;
    top: 10px;
    left: 20px;
    position: absolute;

    @media screen and (max-width: 700px) {
      width: 150px;
    }
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  .bottom-part {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 48px);
    padding: 24px;
    margin: auto;
    align-items: flex-end;

    .left-text {
      width: 70%;
    }
    h2 {
      margin: 0;
      color: white;
      font-size: 40px;
      @media screen and (max-width: 700px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 20px;
    }
    @media screen and (max-width: 700px) {
      padding: 10px;
    }
    .right-part {
      text-align: center;

      span {
        font-size: 15px;
        font-weight: bold;
      }
      span.offer {
        color: #ea4c82;
        text-decoration: line-through;
        margin-right: 5px;
      }
    }
    button {
      // margin: auto;
      font-weight: bold;
      padding: 15px 30px;
      font-size: 24px;
      background: var(--primary-orange);
    }
  }
}
div.features {
  h3 {
    text-align: center;
  }

  div.inner-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    iframe {
      width: 45%;
      height: auto;
      border-radius: 10px;
    }
    div.list-features {
      width: 52%;

      .list-inner {
        display: flex;
        align-items: center;
        padding: 10px;

        .list-img {
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .list-right {
          h4,
          p {
            margin: 0;
          }
          h4 {
            background: linear-gradient(
              90deg,
              rgba(43, 172, 217, 1) 9%,
              rgba(68, 182, 120, 1) 87%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            font-size: 15px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      iframe {
        width: 80%;
        margin: auto;
        height: 300px;
      }
      div.list-features {
        margin: 20px auto;
        width: 80%;
      }
    }
    @media screen and (max-width: 600px) {
      iframe,
      div.list-features {
        width: 100%;
      }
    }
  }
}

div.testimonials {
  h3 {
    text-align: center;
  }
  div.testimonials-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  div.testimonial {
    // width: 20%;
    max-width: 280px;
    .img {
      img {
        height: 280px;
        width: 280px;
        border-radius: 50%;
      }
    }
    div.quote,
    span {
      display: block;
      text-align: center;
      margin: auto;
    }
    span {
      color: var(--primary-green);
    }
  }
}

div.get-started {
  margin: 40px 0;
  text-align: center;
  .right-part {
    span {
      font-weight: bold;

      font-size: 15px;
    }
    span.offer {
      color: #ea4c82;
      text-decoration: line-through;
      margin-right: 5px;
    }
  }
  button {
    margin: auto;
    font-weight: bold;
    padding: 15px 30px;
    font-size: 24px;
    background: var(--primary-orange);
  }
}
</style>